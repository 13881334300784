<template>
  <div class="row">
    <div class="col-md-8 offset-2">
      <div class="card form-body">
        <div class="card-body">
          <legend><h5>User Information</h5></legend>
          <hr />
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for=""
                  >First Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="first_name"
                  :class="errors['first_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['first_name']" class="text-danger">{{
                  errors["first_name"][0]
                }}</span>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for=""
                  >Last Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  Last
                  Name=""
                  class="form-control"
                  id=""
                  v-model="last_name"
                  :class="errors['last_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['last_name']" class="text-danger">{{
                  errors["last_name"][0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Email <span class="text-danger">*</span></label>
            <input
              disabled
              type="email"
              name=""
              class="form-control"
              id=""
              v-model="email"
              :class="errors['email'] ? 'border border-danger' : ''"
            />
            <span v-if="errors['email']" class="text-danger">{{
              errors["email"][0]
            }}</span>
          </div>

          <div class="form-group" v-if="currentUser.u.roles == 'admin'">
            <label for="name"
              >Notification Email <span class="text-danger">*</span></label
            >
            <input
              type="text"
              name="name"
              id=""
              class="form-control"
              v-model="notification_email"
              :class="
                errors['notification_email'] ? 'border border-danger' : ''
              "
            />
            <span v-if="errors['notification_email']" class="text-danger">{{
              errors["notification_email"][0]
            }}</span>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div
                class="form-group"
                v-if="!currentUser.u.is_admin && currentUser.u.roles == 'admin'"
              >
                <label for="name">Show Contact (for email) </label>
                <input
                  type="text"
                  name="name"
                  id=""
                  v-mask="'(###)-###-####'"
                  class="form-control"
                  v-model="show_contact"
                  :class="errors['show_contact'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['show_contact']" class="text-danger">{{
                  errors["show_contact"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                v-if="!currentUser.u.is_admin && currentUser.u.roles == 'admin'"
              >
                <label for="display_name">Display Name for Scheduler </label>
                <input
                  type="text"
                  name="display_name"
                  id=""
                  class="form-control"
                  v-model="display_name"
                  :class="errors['display_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['display_name']" class="text-danger">{{
                  errors["display_name"][0]
                }}</span>
              </div>
            </div>
          </div>

          <!-- <div class="form-group">
            <label for="">Username <span class="text-danger">*</span></label>
            <input
              type="text"
              name=""
              class="form-control"
              id=""
              v-model="username"
              :class="errors['username'] ? 'border border-danger' : ''"
            />
            <span v-if="errors['username']" class="text-danger">{{
              errors["username"][0]
            }}</span>
          </div> -->
          <button class="btn btn-warning" @click="togglePassword">
            Change Password
          </button>

          <div v-show="toggle_password">
            <hr />
            <div class="form-group m-form__group row">
              <label for="old_password" class="col-3 col-form-label">
                Old Password
              </label>
              <div class="col-5">
                <input
                  class="form-control m-input"
                  type="password"
                  id="old_password"
                  v-model="old_password"
                  :class="errors['old_password'] ? 'border border-danger' : ''"
                />
              </div>
              <span v-if="errors.old_password" class="text-danger">{{
                errors.old_password
              }}</span>
            </div>
            <div class="form-group m-form__group row">
              <label for="new_password" class="col-3 col-form-label">
                New Password
              </label>
              <div class="col-5">
                <input
                  class="form-control m-input"
                  type="password"
                  id="new_password"
                  v-model="new_password"
                  :class="errors.new_password ? 'border border-danger' : ''"
                />
              </div>
              <span v-if="errors.new_password" class="text-danger">{{
                errors.new_password
              }}</span>
            </div>
            <div class="form-group m-form__group row">
              <label for="confirm_password" class="col-3 col-form-label">
                Confirm Password
              </label>
              <div class="col-5">
                <input
                  class="form-control m-input"
                  type="password"
                  id="confirm_password"
                  v-model="confirm_password"
                  :class="errors.confirm_password ? 'border border-danger' : ''"
                />
              </div>
              <span v-if="errors.confirm_password" class="text-danger">{{
                errors.confirm_password
              }}</span>
            </div>
            <button
              class="btn btn-success"
              @click="updatePassword('kt_update_password')"
              style="float: left"
              ref="kt_update_password"
            >
              <i class="fa fa-check"></i>Update Password
            </button>
          </div>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
            v-if="!toggle_password"
          >
            <i class="fa fa-check"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  GET_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_PASSWORD,
} from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Setting",
          route: "",
        },
        {
          id: 3,
          title: "Profile",
          route: "",
        },
      ],
      isEdit: false,

      errors: [],

      regEmail:
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      //form-data
      username: "",
      notification_email: "",
      display_name: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      is_active: true,
      toggle_password: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      show_contact: "",
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
  created() {
    this.toggle_password = false;
    if (this.$route.name == "admin.profile") {
      this.breadCrumbs[0].route = "admin.dashboard";
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(GET_PROFILE_DATA).then((data) => {
      this.username = data.data.username;

      this.first_name = data.data.first_name;
      this.last_name = data.data.last_name;
      this.email = data.data.email;
      if (this.currentUser.u.roles == "admin") {
        this.notification_email = data.data.notification_email;
        this.show_contact = data.data.show_contact;
        this.display_name =
          data.data.display_name == null ? "" : data.data.display_name;
      }
    });
  },
  methods: {
    validateEmail(value) {
      if (this.regEmail.test(value)) {
        this.errors["email"] = "";
      } else {
        this.errors["email"] = "Please enter a valid email address";
      }
    },
    updateEmail(e) {
      this.validateEmail(e.target.value);
    },
    togglePassword() {
      this.toggle_password = !this.toggle_password;
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
    //
    update(ref) {
      this.errors = [];
      this.loadingButton(ref);

      var _data = {
        username: this.username,
        name: this.first_name + " " + this.last_name,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        notification_email: this.notification_email.replace(" ", ""),
        display_name: this.display_name,
        status: false,
        show_contact: this.show_contact,
      };

      if (!this.regEmail.test(this.email)) {
        this.closeBtnLoad(ref);
        this.$toastr.e("Please enter a valid email address");
      } else {
        this.$store
          .dispatch(UPDATE_PROFILE_DATA, { data: _data })
          .then((data) => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            if (this.$route.name == "admin.profile") {
              this.$router.push({ name: "admin.profile" });
            } else {
              this.$router.push({ name: "client.profile" });
            }
          })
          .catch((err) => {
            this.errors = err.errors;
            this.closeBtnLoad(ref);
          });
      }
    },
    updatePassword(ref) {
      this.errors = [];

      if (this.toggle_password) {
        if (this.new_password != "" && this.confirm_password != "") {
          if (this.new_password != this.confirm_password) {
            this.errors.confirm_password = "Password Mismatched";
          } else {
            this.loadingButton(ref);
            var _data = {
              new_password: this.new_password,
              old_password: this.old_password,
            };

            this.$store
              .dispatch(UPDATE_PROFILE_PASSWORD, { data: _data })
              .then((data) => {
                this.$toastr.s(data.message);
                this.new_password = "";
                this.old_password = "";
                this.confirm_password = "";
                this.closeBtnLoad(ref);
                this.toggle_password = false;
              })
              .catch((err) => {
                this.errors = err;
                this.closeBtnLoad(ref);
                this.new_password = "";
                this.old_password = "";
                this.confirm_password = "";
              });
          }
        } else if (this.new_password == "" && this.confirm_password == "") {
          this.errors.new_password = "This field is required";
        } else if (this.confirm_password == "") {
          this.errors.confirm_password = "This field is required";
        } else if (this.new_password == "") {
          this.errors.new_password = "This field is required";
        }

        // else
        // {

        // }
      }
    },
  },
};
</script>

<style></style>
